import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'library/Button';
import { createSystemPageURI } from 'helpers/createSystemPageURI';
import { getDeviceTypeByName } from 'helpers/Device/getDeviceType';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

import './StoreSerialNumbers.scss';

const linkVariants = ['transparent'];

export const SerialNumberLink = ({ device }) => {
    const systemUrl = useMemo(() => createSystemPageURI({
        deviceUID: device.Device_UID,
        deviceTypeID: device.Device_DeviceType_ID || getDeviceTypeByName(device.Device_Name),
        deviceMainVersion: BuildVersions.normalizeVersionString(device.Device_MainVersion),
    }), [device]);

    return (
        <div className={classNames(
            'hme-stores-status__list__inner-cell',
            'hme-stores-status--public__grid__inner-cell',
            'hme-stores-status--public__grid__inner-cell--serial-number'
        )}>
            <Button as={Link} to={systemUrl} variants={linkVariants}>{device.Device_SerialNumber}</Button>
        </div>
    );
};

export const StoreSerialNumbers = ({ devices }) => (
    <div className='hme-stores-status--public__grid__device-serial-number'>
        {
            devices.map((device) => (
                <SerialNumberLink device={device} key={device.Device_UID} />
            ))
        }
    </div>
);

// add prop validation
SerialNumberLink.propTypes = {
    device: PropTypes.object.isRequired,
};

StoreSerialNumbers.propTypes = {
    devices: PropTypes.array.isRequired,
};
