import React from 'react';

import { CommonConstants } from 'Constants';
import { hasGamificationFeature, checkAccess } from 'actions/permissions';
import { navigationService } from 'components/Common/NavigationServices';
import {
    isApplySettingsEnabled,
    hasDistributorViewAllStoresAccess,
    hasDistributorManageDevicesAdvancedAccess,
    hasTextConnectAccess,
} from 'helpers/access';
import { checkBaseAccessForAccounts } from 'helpers/distributor';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { Config } from '../../../Config';
import { COMMON_ROUTES } from 'constants/routes';
import { checkNitroEssentials } from 'helpers';

const isLeaderboardItemsAllowed = () => {
    const { subscription } = CommonConstants;
    const leaderBoardSubscription = [subscription.premiumStoreDriveThru, subscription.nitroLeaderboard];
    const userContext = baseAuthService.getProfile();
    const { IsNitro: isNitroEnabled, SL: subscriptionType } = userContext;
    const isNitroOrGamifiedNitroLB = isNitroEnabled && (subscriptionType === 2 || subscriptionType === 6);

    return leaderBoardSubscription.includes(subscriptionType) || isNitroOrGamifiedNitroLB;
};

const homeItem = {
    name: 'welcome',
    tKey: 'main-header__item--welcome',
    icon: <i className='icon icon-home' />,
    topMenu: false,
    url: navigationService.getUrl('welcome'),
    className: 'hme-menu-item--with-home-icon',
    hasAccess: true,
}

const dashboardItem = {
    name: 'dashboard',
    tKey: 'common__dashboard',
    icon: <i className='icon icon-dashboard' />,
    topMenu: false,
    className: 'hme-menu-item--with-dashboard-icon',
    url: navigationService.getUrl('dashboard'),
    hasAccess: true,
};

const reportOverviewItem = {
    name: 'overview',
    tKey: 'reports__overview',
    url: COMMON_ROUTES.overview,
    hasAccess: true
};

const singleStoreReportItem = {
    name: 'singleStore',
    tKey: 'reports__singleStore',
    url: COMMON_ROUTES.single,
    hasAccess: true
};

const multiStoreReportItem = {
    name: 'multiStore',
    tKey: 'reports__multiStore',
    url: COMMON_ROUTES.multi,
    hasAccess: true
};

const rawDataReportItem = {
    name: 'rawData',
    tKey: 'reports__rawData',
    url: COMMON_ROUTES.rcd,
    hasAccess: true
};

const schedulerReportItem = {
    name: 'scheduler',
    tKey: 'reports__scheduler',
    url: COMMON_ROUTES.scheduledReports,
    hasAccess: true
};
// fix for the bug DW-1204
const reportUserGuideItem = {
    name: 'reportUserGuide',
    tKey: 'reports__docs',
    url: 'reports__docs__url',
    hasAccess: true,
    translateUrl: true,
    tOptions: {
        url: Config.trainingPortalBaseUrl
    },
    openBlank: true
};

const trendReportItem = {
    name: 'trends',
    tKey: 'reports__trends',
    url: COMMON_ROUTES.trends,
    hasAccess: true
};

const trendsDashboardItem = {
    name: 'trends-dashboard',
    tKey: 'reports__trends-dashboard',
    url: COMMON_ROUTES.trendsHistory,
    hasAccess: true
};

const OutliersClientItem = {
    name: 'outliers-client',
    tKey: 'admin__hme-reports__sub-header--outliers',
    url: COMMON_ROUTES['outliers-client'],
    hasAccess: true
};

const performanceAnalysisItem = {
    name: 'performance-analysis',
    tKey: 'reports__title-performance-analysis',
    url: COMMON_ROUTES['performance-analysis'],
    hasAccess: true
};

const reportItem = {
    name: 'reports',
    tKey: 'common__reports',
    icon: <i className='icon icon-reports' />,
    topMenu: false,
    className: 'hme-menu-item--with-reports-icon',
    url: navigationService.getUrl('reports'),
    hasAccess: true,
    items: [
        reportOverviewItem,
        multiStoreReportItem,
        singleStoreReportItem,
        trendsDashboardItem,
        trendReportItem,
        OutliersClientItem,
        performanceAnalysisItem,
        rawDataReportItem,
        schedulerReportItem,
        reportUserGuideItem
    ]
};

const leaderboardGroupsItem = {
    name: 'groups',
    tKey: 'common__leaderboards',
    url: '/leaderBoards/LBGroups',
    hasAccess: true,
};

const leaderboardTemplatesItem = {
    name: 'templates',
    tKey: 'sub-header--lb__templates',
    url: '/leaderBoards/LBTemplatesList',
    hasAccess: true,
};

const leaderboardContestsItem = {
    name: 'contests',
    tKey: 'common__contests',
    url: '/leaderBoards/contests',
    hasAccess: true,
};

const leaderboardAwardsItem = {
    name: 'awards',
    tKey: 'common__awards',
    url: '/leaderBoards/awards',
    hasAccess: true,
};

const leaderboardSmackTalkItem = {
    name: 'smackTalk',
    tKey: 'common__smack-talk',
    url: '/leaderBoards/smackTalk',
    hasAccess: hasGamificationFeature('smacktalk'),
};

const leaderboardAvatarBoxItem = {
    name: 'avatarBox',
    tKey: 'sub-header--lb__avatars',
    url: '/leaderBoards/avatarBox',
    hasAccess: true,
};

const leaderboardAnnouncementItem = {
    name: 'announcement',
    tKey: 'common__announcement-settings',
    url: '/leaderBoards/announcementSettings',
    hasAccess: true,
};

const allowedLeaderboardItem = {
    name: 'leaderBoards',
    tKey: 'common__leaderboards',
    icon: <i className='icon icon-dashboard' />,
    className: 'hme-menu-item--with-dashboard-icon',
    topMenu: false,
    url: navigationService.getUrl('leaderBoards'),
    hasAccess: isLeaderboardItemsAllowed(),
    items: [
        leaderboardGroupsItem,
        leaderboardTemplatesItem,
        leaderboardContestsItem,
        leaderboardAwardsItem,
        leaderboardSmackTalkItem,
        leaderboardAvatarBoxItem,
        leaderboardAnnouncementItem
    ],
};

const notAllowedLeaderboardItem = {
    name: 'leaderBoards',
    tKey: 'common__leaderboards',
    url: '/leaderBoards/LBGroups',
    icon: <i className='icon icon-dashboard' />,
    className: 'hme-menu-item--with-dashboard-icon',
    topMenu: false,
    url: navigationService.getUrl('leaderBoards'),
    hasAccess: !isLeaderboardItemsAllowed(),
    allowed: ['templates']
};

const settingsStoresItem = {
    name: 'stores',
    tKey: 'common__stores',
    url: navigationService.getUrl('stores'),
    hasAccess: true,
};

const settingsUsersItem = {
    name: 'users',
    tKey: 'common__users',
    url: navigationService.getUrl('users'),
    hasAccess: true,
};

const settingsRolesItem = {
    name: 'roles',
    tKey: 'common__roles',
    url: navigationService.getUrl('roles'),
    hasAccess: true,
};

const settingsDisplayItem = {
    name: 'display',
    tKey: 'sub-header--settings__display',
    url: navigationService.getUrl('display'),
    hasAccess: () => !checkNitroEssentials(),
};

const settingsLockDeviceItem = {
    name: 'lockDeviceSettings',
    tKey: 'sub-header--settings__lock-device-settings',
    url: navigationService.getUrl('lockDeviceSettings'),
    hasAccess: checkAccess('EditDeviceSettings'),
};

const settingsApplyDeviceSettingsItem = {
    name: 'applyDeviceSettings',
    tKey: hasDistributorViewAllStoresAccess() ? 'common__device-settings' : 'common__apply-device-settings',
    url: navigationService.getUrl('applyDeviceSettings'),
    hasAccess: isApplySettingsEnabled() || hasDistributorManageDevicesAdvancedAccess,
};

const settingsSnapshotsItem = {
    name: 'manageDeviceSettings',
    tKey: 'common__settings-snapshots',
    url: navigationService.getUrl('manageDeviceSettings'),
    // TODO change the isApplySettingsEnabled to defined permission for managing snapshots for public users
    hasAccess: hasDistributorManageDevicesAdvancedAccess() || isApplySettingsEnabled(), 
};

const mergeDevicesItem = {
    name: 'mergeDevices',
    tKey: 'common__merge-devices',
    url: navigationService.getUrl('mergeDevices'),
    hasAccess: hasDistributorManageDevicesAdvancedAccess(),
};

const settingsItem = {
    name: 'stores',
    tKey: 'common__settings',
    icon: <i className='icon icon-settings' />,
    className: 'hme-menu-item--with-settings-icon',
    topMenu: false,
    url: navigationService.getUrl('stores'),
    hasAccess: true,
    items: [
        settingsStoresItem,
        settingsUsersItem,
        settingsRolesItem,
        settingsDisplayItem,
        settingsLockDeviceItem,
        settingsSnapshotsItem,
        settingsApplyDeviceSettingsItem,
        mergeDevicesItem
    ],
};

const smackTalkMenuItem = {
    name: 'smackTalk',
    tKey: 'common__smack-talk',
    icon: <i className='icon hme-smacktalk_icon' />,
    topMenu: true,
    url: navigationService.getUrl('smackTalk'),
    hasAccess: hasGamificationFeature('smacktalk'),
};

const textConnectMenuItem = {
    name: 'text-connect',
    tKey: 'common__text-n-connect',
    icon: <i className='icon hme-text-connect_icon' />,
    topMenu: true,
    url: navigationService.getUrl('text-connect'),
    className: 'hme-menu-item--text-connect',
    hasAccess: hasTextConnectAccess()
};

const distributorAccountsItem = {
    name: 'distributorAccounts',
    tKey: 'common__accounts',
    icon: <i className="icon icon-account" />,
    topMenu: false,
    url: navigationService.getUrl('distributorAccounts'),
    className: 'hme-menu-item--with-accounts-icon',
    hasAccess: checkBaseAccessForAccounts(),
}

const menuItems = [
    homeItem,
    distributorAccountsItem,
    dashboardItem,
    reportItem,
    allowedLeaderboardItem,
    notAllowedLeaderboardItem,
    settingsItem,
    smackTalkMenuItem,
    textConnectMenuItem
];

const isActiveTab = (item) => {
    const activeTab = navigationService.getPublicSelectedMenu();

    return activeTab === item.name || (item.allowed && item.allowed.includes(activeTab)) || window.location.pathname.indexOf(item.url) !== -1;
};

export const getMenuItems = (items = menuItems) => {
    return items
        .filter(({ hasAccess }) => typeof hasAccess === 'function' ? hasAccess() : hasAccess)
        .map((item) => ({
            ...item,
            items: item.items && getMenuItems(item.items)
        }))
        .filter(({ items }) => !items || items.length)
        .map((item) => {
            const isActive = item.items ? item.items.some(({ isActive }) => isActive) : isActiveTab(item);

            return ({
                ...item,
                isActive,
            });
        });
};
