import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Title as LibTitle } from 'library/Title';
import getDeviceType from 'helpers/Device/getDeviceType';
import { getAvailableDeviceUpgradeVersions } from '../../../Common/helpers';
import { TileDeviceList } from '../TileDeviceList';
import { Constant } from '@hme-cloud/utility-common';

const { BuildVersions } = Constant;

const prepareDevices = ({
    device,
    availableDeviceUpgradeVersions
}) => {
    const deviceType = getDeviceType(device);

    let latestAvailableDeviceUpgradeVersions = '';
    if (availableDeviceUpgradeVersions.length > 0) {
        latestAvailableDeviceUpgradeVersions =
            availableDeviceUpgradeVersions[availableDeviceUpgradeVersions.length - 1];
    }

    const isOnLatestVersion =
        !latestAvailableDeviceUpgradeVersions.Version ||
        (device.mainVersion &&
            BuildVersions.semverGte(
                    BuildVersions.formatVersion(device.mainVersion) || '0.0.0',
                    latestAvailableDeviceUpgradeVersions.Version
            )
        );

    const preparedDevice = {
        modelName: deviceType.hardwareName,
        serialNumber: device.Device_SerialNumber,
        macAddress: device.Device_MacAddress,
        mainVersion: device.Device_MainVersion,
        latestFirmwareAvailable: latestAvailableDeviceUpgradeVersions.Version,
        onLatestVersion: isOnLatestVersion ? <Trans i18nKey="common__yes" /> : <Trans i18nKey="common__no" />
    };

    return [preparedDevice];
};

const GRID_OPTIONS = ([
    {
        text: 'common__device',
        flex: 0.7,
        property: 'modelName',
        className: 'hme-grid-model-name',
        headerClassName: 'hme-grid-model-name'
    },
    {
        text: 'common__device__serial-number',
        flex: 1,
        property: 'serialNumber',
        tabletScreenText: 'common__device__serial-number--text'
    },
    {
        text: 'system-status__mac-address',
        flex: 1.2,
        property: 'macAddress'
    },
    {
        text: 'system-status__devices-grid__current-firmware',
        flex: 1.2,
        property: 'mainVersion',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__latest-firmware-available',
        flex: 1.5,
        property: 'latestFirmwareAvailable',
        className: 'hme-grid-centered-cell',
        headerClassName: 'hme-grid-centered-cell'
    },
    {
        text: 'system-status__devices-grid__on-latest-version',
        flex: 1.2,
        property: 'onLatestVersion',
        className: 'hme-grid-centered-cell hme-grid-cell--uppercase',
        headerClassName: 'hme-grid-centered-cell'
    }
]);

const MOBILE_GRID_OPTIONS = [
    {
        column: GRID_OPTIONS.filter(({ property }) => property !== 'modelName') // removes "model-name" field for non desktop tile-list
    }
];

export const useDevicesGrid = ({
    device,
    deviceSettings,
    deviceUpgradeVersions,
    deviceScheduledUpgrade
}) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (!device || !deviceSettings || !deviceScheduledUpgrade) return;

        const availableDeviceUpgradeVersions = getAvailableDeviceUpgradeVersions({ device, deviceUpgradeVersions });

        const preparedDevices = prepareDevices({
            device,
            deviceSettings,
            availableDeviceUpgradeVersions,
            deviceScheduledUpgrade
        });

        setRows(preparedDevices);
    }, [device, deviceSettings, deviceScheduledUpgrade]);

    const Title = <LibTitle><Trans i18nKey="system-status__title__system-devices" /></LibTitle>;

    return {
        Title,
        rows,
        rowKey: 'serialNumber',
        headers: GRID_OPTIONS,
        mobileHeaders: MOBILE_GRID_OPTIONS,
        tileProps: {
            ListItemComponent: TileDeviceList
        }
    };
};
