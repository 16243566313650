import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { createSystemPageURI } from 'helpers/createSystemPageURI';

export const ViewDeviceLink = ({ device }) => {
    const { t } = useTranslation();

    const {
        Device_UID: deviceUID,
        Device_DeviceType_ID: deviceTypeID,
        Device_MainVersion: deviceMainVersion
    } = device;

    const redirectUrl = useMemo(() => {
        return createSystemPageURI({ deviceUID, deviceTypeID, deviceMainVersion } );
    }, [deviceUID, deviceTypeID, deviceMainVersion]);

    return (
        <Link to={redirectUrl}>{t('stores__modal__view-details')}</Link>
    );
};

ViewDeviceLink.propTypes = {
    device: PropTypes.shape({
        Device_UID: PropTypes.string,
        Device_DeviceType_ID: PropTypes.number,
        Device_MainVersion: PropTypes.string
    }).isRequired
};
