import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { InputComponent } from 'components/Inputs';
import { Label } from 'library/Label';

import './Address.scss';

export const Address = ({
    value,
    isReadOnly,
    variants = [],
    className = ''
}) => {
    const { t } = useTranslation();

    const {
        line1,
        line2,
        line3,
        line4,
        city,
        zipCode,
        region,
        country
    } = value;

    const cityZipRegion = useMemo(() => {
        const comma = city && (zipCode || region) ? ', ' : '';
        return `${city.trim()}${comma}${region.trim()} ${zipCode.trim()}`;
    }, [city, zipCode, region]);

    return (
        <div className={
            classNames(
                    'hme-address',
                    variants.map((v) => `hme-address--${v}`),
                    className
            )}
        >
            <Label>{t('common__address')}</Label>
            {line1 && <InputComponent
                value={line1}
                isReadOnly={isReadOnly}
            />}
            {line2 && <InputComponent
                value={line2}
                isReadOnly={isReadOnly}
            />}
            {line3 && <InputComponent
                value={line3}
                isReadOnly={isReadOnly}
            />}
            {line4 && <InputComponent
                value={line4}
                isReadOnly={isReadOnly}
            />}
            {(city || zipCode || region) && <span className="hme-address__text">{cityZipRegion}</span>}
            {country && <InputComponent
                value={country}
                isReadOnly={isReadOnly}
            />}
        </div>
    );
};

Address.propTypes = {
    value: PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        line3: PropTypes.string,
        line4: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        region: PropTypes.string,
        country: PropTypes.string
    }).isRequired,
    isReadOnly: PropTypes.bool,
    variants: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string
};
