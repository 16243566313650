import { pluck } from 'ramda';

import { getDataAsync, postDataAsync } from 'Api';
import { Config } from 'Config';
import { CommonConstants } from 'Constants';
import { DO_NOT_HAVE_ACCESS_KEY, DEFAULT_IP } from 'constants/login';
import { baseAuthService } from 'components/Security/AuthenticationService';
import { types as companyTypes } from 'constants/company';
import { LOCAL_STORAGE_KEYS as userNotificationsKeys } from 'constants/userNotifications';

import { PublicClientApplication } from '@azure/msal-browser';

const config = {
    auth: {
        clientId: Config.clientID,
        authority: Config.authority,
        postLogoutRedirectUri: `${window.location.origin}/admin`
    },
    system: {
        loggerOptions: {
            loggerCallback(loglevel, message) {
                console.log(message);
            },
            piiLoggingEnabled: false,
            logLevel: 0
        }
    }
};

const pca = new PublicClientApplication(config);

const tokenRequest = {
    scopes: ['User.Read']
};

const getPermissionNames = pluck('Permission_Name');

// Some functions use old service to avoid double implementation of code. In the future it will be great to migrate for new service.
export const isAdmin = () => {
    return baseAuthService.isAdmin();
};

export const getToken = () => {
    return baseAuthService.getToken();
};

export const decodeToken = (token) => {
    if (!token) {
        return {};
    }

    try {
        return baseAuthService.decodeToken(token);
    } catch (err) {
        console.log(err);
    }

    return {};
};

export const getProfile = () => {
    return decodeToken(getToken());
};

export const isPartner = () => {
    return baseAuthService.isPartner() ?? getProfile().partner;
};

export const getIp = () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getUserIP;

    return getDataAsync(url);
};

export const clearAuthData = () => {
    return baseAuthService.clear();
};

const getLoginError = (message) => {
    switch (message) {
        case DO_NOT_HAVE_ACCESS_KEY:
            return Error('admin__login__youDoNotHaveAccess');
        case 'usernameinvalid':
            return Error('login__error--fail');
        default:
            return Error('login__error--fail');
    }
};

const checkErrorLoginData = (loginResult) => {
    if (loginResult.error !== 'Unauthorized') {
        throw new Error('common__error--internal-server');
    }

    const { message } = loginResult;

    const error = getLoginError(message);
    error.loginResult = loginResult;

    throw error;
};

const saveIdToken = (authToken) => {
    return baseAuthService.setIdToken(authToken);
};

export const saveContextToken = (authToken) => {
    return baseAuthService.setContextToken(authToken);
};


const isTokenExpired = (token) => {
    return baseAuthService.isTokenExpired(token);
};

export const isLoggedIn = () => {
    return baseAuthService.isLoggedIn();
};

export const getUUID = () => {
    return baseAuthService.getUUID();
};

export const setSelectedUUID = (uuid) => {
    window.localStorage.setItem('selected_uuid', uuid);
};

export const getSelectedUUID = () => {
    return window.localStorage.getItem('selected_uuid');
};

export const removeSelectedUUID = () => {
    window.localStorage.removeItem('selected_uuid');
    window.localStorage.removeItem('uuid');
};

const getUserPermissions = async () => {
    const baseUrl = Config.apiBaseUrl + CommonConstants.apiUrls.getPermissions;
    const searchParams = new URLSearchParams();

    const uuid = getUUID();

    if (uuid) {
        searchParams.set('uuid', uuid);
    }

    const url = `${baseUrl}?${searchParams.toString()}`;

    const response = await getDataAsync(url);

    return getPermissionNames(response.data[0]);
};

const getAdminPermissions = (loginResult) => {
    return loginResult.permissions;
};

const getPermissions = (isAdminAccount, loginResult) => {
    return isAdminAccount ? getAdminPermissions(loginResult) : getUserPermissions();
};

export const setPermission = (data) => {
    baseAuthService.setPermission(data);
};

export const setAdminPermission = (data) => {
    return baseAuthService.setAdminPermission(data);
};

const savePermissions = (permissions, isAdminAccount) => {
    return isAdminAccount ? setAdminPermission(permissions) : setPermission(permissions);
};

export const getAccountPermissions = (isAdminAccount) => {
    return isAdminAccount ? baseAuthService.getAdminPermission() : baseAuthService.getPermission();
};

export const isDFARequired = () => {
    const profile = getProfile();

    return profile && profile.Account_DFA_IsDFA && !window.localStorage.getItem('isDFAVerified');
};

export const dfaAuth = async () => {
    const url = Config.apiBaseUrl + CommonConstants.apiUrls.getDFAAccount;
    const accountDFAIP = DEFAULT_IP;
    const accountDFABrowser = navigator.userAgent;

    const dfaData = {
        accountDFAIP,
        accountDFABrowser
    };

    try {
        return await postDataAsync(url, dfaData);
    } catch (err) {
        return {};
    }
};

const adminLogin = async (loginUrl, loginData) => {
    // clear session storage before admin login as it can impact msal login process
    let loginResult;
    window.sessionStorage.clear();
    await pca.initialize();
    const tokenResponse = await pca.acquireTokenPopup(tokenRequest);
    if (tokenResponse && tokenResponse.accessToken) {
        loginResult = await postDataAsync(loginUrl, { ...loginData, tokenResponse });
    }
    return loginResult;
};

const publicUserLogin = async (loginUrl, loginData) => {
    const loginResult = await postDataAsync(loginUrl, loginData, {
        withCredentials: true,
        maxRedirects: 0
    });

    return loginResult;
};

const setLoginData = (loginResult) => {
    if (loginResult.hmeportal_server_version) {
        window.localStorage.setItem('hmeportal_server_version', loginResult.hmeportal_server_version);
    }

    if (!loginResult.accessToken) {
        throw new Error('common__error--internal-server');
    }

    if (isTokenExpired(loginResult.accessToken)) {
        throw new Error('common__error--internal-server');
    }

    saveIdToken(loginResult.accessToken);
    saveContextToken(loginResult.accessToken);

    return loginResult;
};

export const loginUser = async (data) => {
    clearAuthData();

    const loginData = {
        ...data,
        isAdmin: isAdmin(),
        isPartner: isPartner()
    };

    const loginUrl = Config.authBaseUrl + Config.tokenPath;
    let loginResult;

    if (loginData.ssoTokenData) {
        loginResult = loginData.ssoTokenData;
    } else {
        try {
            if (isAdmin()) {
                loginResult = await adminLogin(loginUrl, loginData);
            } else {
                loginResult = await publicUserLogin(loginUrl, loginData);
                if ('location' in loginResult) {
                    return loginResult;
                }
            }
        } catch (err) {
            console.log('Login failed:', err);
            if (err.response.data)
                checkErrorLoginData(err.response.data);
            return {};
        }
    }
    loginResult = setLoginData(loginResult);

    if (!isPartner()) {
        const permissions = await getPermissions(loginData.isAdmin, loginResult);

        savePermissions(permissions, loginData.isAdmin);
    }

    return loginResult;
};

export const logOutAdminUser = async () => {
    if (pca) {
        // Check if the user is logged in before performing logout
        const loggedInAccounts = pca.getAllAccounts();
        if (loggedInAccounts && loggedInAccounts.length > 0) {
        // User is logged in, perform logout
            await pca.initialize();
            await pca.logoutRedirect(loggedInAccounts[0].homeAccountId);
        } else {
        // No user is logged in, skip logout operation
            console.log('No Admin User is logged in.');
        }
    }
};

export const forgotPassword = (data) => {
    const url = `${Config.apiBaseUrl}${
        isPartner()
            ? CommonConstants.apiUrls.forgotPasswordPartner
            : CommonConstants.apiUrls.forgotPassword
    }`;

    const resetIP = DEFAULT_IP;
    const resetAgent = navigator.userAgent;

    const requestData = {
        ...data,
        resetIP,
        resetAgent
    };

    return postDataAsync(url, requestData);
};

export const verifyDFACode = (DFACode) => {
    const dfaUrl = Config.apiBaseUrl + CommonConstants.apiUrls.checkDFACode;
    const dfaData = {
        DFACode
    };

    return postDataAsync(dfaUrl, dfaData);
};

export const isMasquerade = () => baseAuthService.isMasquerade();


/**
     * Function to check if user has accepted updated TOS.
     * This check is from service.
     * @return {boolean}
     */
export const getnewTOSFromToken = () => {
    return getProfile().HasAcceptedUpdatedTOS===1 ? true : false;
};
/**
     * Function to check if user has accepted updated TOS.
     * This check is from local storage
     * @return {boolean}
     */
export const getNewTOSFromLocalStorage = () => {
    return window.localStorage.getItem('acceptedUpdatedTOS') ? window.localStorage.getItem('acceptedUpdatedTOS') : false;
};
/**
     * Function to set when user accepts updated TOS.
     * @param {boolean} flag
     */
export const setNewTOSInLocalStorage = (flag=false) => {
    window.localStorage.setItem('acceptedUpdatedTOS', flag);
};

export const isDistributor = () => {
    const profile = getProfile();

    return profile.Company_Type === companyTypes.DISTRIBUTOR;
};

export const isCorporation = () => {
    const profile = getProfile();

    return profile.Company_Type === companyTypes.CORPORATION;
};

export const isFranchise = () => {
    const profile = getProfile();

    return profile.Company_Type === companyTypes.FRANCHISE;
};

export const isPublic = () => {
    return isFranchise() || isDistributor() || isCorporation();
};

export const isAccountOwner = () => {
    return getProfile().IsAccountOwner === 1;
};

export const getAdminProfile = () => baseAuthService.getAdminProfile();

export const fetchUserTokenByAdmin = ({ userEmail, isPartnerUser = false }) => {
    const adminProfile = getAdminProfile();

    const requestData = {
        username: userEmail,
        adminUser: adminProfile?.unique_name ?? null,
        isPartner: isPartnerUser
    };

    const url = `${Config.authBaseUrl}${Config.tokenPath}`;

    return postDataAsync(url, requestData);
};

export const masquaradeUser = (accessToken) => {
    window.localStorage.removeItem('user_email');
    window.localStorage.removeItem('uuid');
    window.localStorage.setItem('ctx_token', accessToken);

    Object.values(userNotificationsKeys).forEach((key) => {
        window.localStorage.removeItem(key);
    });
};

export const getPermission = () => {
    return baseAuthService.getPermission();
};

export const secondsUntilTokenExpires = () => {
    return baseAuthService.secondsUntilTokenExpires();
};

export const refresh = () => {
    baseAuthService.refresh();
};

export const revoke = () => {
    baseAuthService.revoke();
};

export const clearUUID = () => {
    baseAuthService.clearUUID();
};

export const setUserEmail = (email) => {
    baseAuthService.setUserEmail(email);
};

export const getIdToken = () => {
    return baseAuthService.getIdToken();
};

export const getLoggedInUserUID = () => {
    return baseAuthService.getLoggedInUserUID();
};
