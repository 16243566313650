/* eslint-disable complexity */
import { CommonConstants } from 'Constants';

import { getCompanyRegion } from './getCompanyRegion';
import { lbVersions } from './constants'

const { subscription } = CommonConstants;

export const mapAccountToFormData = (account, regions) => {
    const { companyRegionId, companyRegion } = getCompanyRegion({ account, regions });

    return {
        accountOwnerInfo: {
            firstName: account.User_FirstName,
            lastName: account.User_LastName,
            emailAddress: account.User_EmailAddress,
            emailAddressConfirm: account.User_EmailAddress,
        },
        accountSettings: {
            isDualFactorAuthentication: !!account.Account_DFA_IsDFA,
            isTestAccount: !!account.Account_IsTest,
        },
        companyInfo: {
            companyName: account.Company_Name,
            companyCountryId: account.Company_Country_ID,
            companyRegionId,
            region: companyRegion,
            distributorId: account.Distributor_id,
            brandUid: account.Brand_UID,
            companyType: account.Company_Type,
        },
        accountStatus: {
            isAccountActive: !!account.Account_IsActive,
        },
        subscriptionType: {
            subscriptionType: `${account.Account_Subscription_ID}-${account.Account_IsNitro}`,
        },
        jtechSubscriptionType: {
            isJtechCustomerSubscriptionType: account.Account_Subscription_ID === +subscription.jtechCustomerOnly,
            jtechSubscriptionTypes: {
                isLinkwearFree: !!account.Linkwear_Free,
                isLinkwearTask: !!account.Linkwear_Task,
                isLinkwearConnect: !!account.Linkwear_Connect,
                isLinkwearAnalytics: !!account.Linkwear_Analytics,
            },
        },
        engineeringSettings: {
            isDataServicePush: !!account.Account_HasFTPAccess,
            isDataServicePull: !!account.Account_HasAPIAccess,
        },
    };
};

const extractSubscriptionNitro = (subscriptionKey) => {
    return subscriptionKey.indexOf('-') === -1 ?
        [Number(subscriptionKey), 1]
        :
        subscriptionKey.split('-').map(Number);
}

const getLeaderBoardInfo = (subscriptionTypeID, subscriptionIsNitro) => {
    const requiredNitroSubscriptions = [subscription.nitroLeaderboard, subscription.nitroGamification].map(Number);
    const isLeaderBoard = [...requiredNitroSubscriptions, +subscription.premiumStoreDriveThru].includes(
        subscriptionTypeID,
    );

    if (!isLeaderBoard) {
        return {
            isLeaderBoard,
            lbVersion: lbVersions.HME,
        };
    }

    return {
        isLeaderBoard,
        lbVersion: subscriptionIsNitro 
            ? lbVersions.NITRO // for nitroLeaderboard & nitroGamification
            : lbVersions.AZURE, // for premiumStoreDriveThru
    };
};

export const mapFormDataToAccount = ({ formData, regions, account }) => {
    const {
        accountOwnerInfo,
        accountSettings,
        companyInfo,
        accountStatus,
        subscriptionType: subscriptionTypeForm,
        jtechSubscriptionType,
    } = formData;
    const { firstName, lastName, emailAddress } = accountOwnerInfo;
    const { isDualFactorAuthentication, isTestAccount } = accountSettings;
    const { companyName, companyType, companyCountryId, companyRegionId, region, distributorId, brandUid } =
        companyInfo;
    const { isAccountActive } = accountStatus || {};
    const { subscriptionType } = subscriptionTypeForm;
    const { jtechSubscriptionTypes } = jtechSubscriptionType;

    let companyRegionCode = '';
    if (companyRegionId) {
        const companyRegion = (regions || []).find((region) => region.Id === companyRegionId);
        companyRegionCode = companyRegion ? companyRegion.Abbreviation : '';
    }

    const { isLinkwearFree, isLinkwearTask, isLinkwearConnect, isLinkwearAnalytics } = jtechSubscriptionTypes;
    const [subscriptionTypeID, subscriptionIsNitro] = extractSubscriptionNitro(subscriptionType);

    const leaderBoardInfo = getLeaderBoardInfo(subscriptionTypeID, subscriptionIsNitro);

    return account
        ? {
              User_ID: account.User_ID,
              User_UID: account.User_UID,
              Account_ID: account.Account_ID,
              Account_UID: account.Account_UID,
              User_FirstName: firstName,
              User_LastName: lastName,
              Company_ID: account.Company_ID,
              Company_UID: account.Company_UID,
              Company_Name: companyName,
              Company_Type: companyType,
              Company_Country_ID: companyCountryId,
              Company_Region: companyRegionCode || region,
              Distributor_id: distributorId,
              Brand_UID: brandUid,
              User_EmailAddress: account.User_EmailAddress,
              updatedEmail: emailAddress,
              Account_Subscription_ID: subscriptionTypeID,
              Account_IsTest: isTestAccount,
              Account_IsDFA: isDualFactorAuthentication,
              Account_IsActive: isAccountActive,
              Linkwear_Free: isLinkwearFree ? 1 : 0,
              Linkwear_Task: isLinkwearTask ? 1 : 0,
              Linkwear_Connect: isLinkwearConnect ? 1 : 0,
              Linkwear_Analytics: isLinkwearAnalytics ? 1 : 0,
              IsNitro: subscriptionIsNitro,
              isUpdateDistributor: distributorId !== account.Distributor_id,
              leaderBoard: leaderBoardInfo.isLeaderBoard,
              LBVersion: leaderBoardInfo.lbVersion,
              Company_AvanteCust_ID: account.Company_AvanteCust_ID,
          }
        : {
              Company_Name: companyName,
              Company_Type: companyType,
              Company_Country_ID: companyCountryId,
              Company_Region: region || companyRegionCode,
              Distributor_id: distributorId,
              Brand_UID: brandUid,
              User_FirstName: firstName,
              User_LastName: lastName,
              User_EmailAddress: emailAddress,
              Account_Subscription_ID: subscriptionTypeID,
              Account_IsTest: isTestAccount,
              Account_TOS_Browser: '',
              Account_IsDFA: isDualFactorAuthentication,
              IsNitro: subscriptionIsNitro,
              Linkwear_Free: isLinkwearFree ? 1 : 0,
              Linkwear_Task: isLinkwearTask ? 1 : 0,
              Linkwear_Connect: isLinkwearConnect ? 1 : 0,
              Linkwear_Analytics: isLinkwearAnalytics ? 1 : 0,
              leaderBoard: leaderBoardInfo.isLeaderBoard,
              LBVersion: leaderBoardInfo.lbVersion,
          };
};
