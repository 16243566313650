/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import { compose } from 'ramda';

import { Config } from '../../Config';
import AuthenticationService from '../Security/AuthenticationService';
import NavigationServices from '../Common/NavigationServices';
import { checkAccess } from '../../actions/permissions';
import { isApplySettingsEnabled } from 'helpers/access';
import { CommonConstants } from "../../Constants";

import './more.css';
import { withSubscriptionBasedExclusions } from '../../HOCs/withSubscriptionBasedExclusions';
import { isDistributor } from 'services/Auth';
import { checkNitroEssentials } from 'helpers';

const hasDistributorAccess = () => isDistributor() && checkAccess('ViewAllStores');
const hasDistributorManageDevicesAdvancedAccess = () => isDistributor()
    && checkAccess(CommonConstants.externalPermissions.ManageDevicesAdvanced);

/**
 *
 */
class SettingsHeader extends React.Component {
    /**
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);

        this.authService = new AuthenticationService(Config.authBaseUrl);
        const path = window.location.pathname;

        this.state = {
            isEditDeviceSettingsEnabled: checkAccess('EditDeviceSettings'),
            // TODO change the isApplySettingsEnabled to defined permission for managing snapshots for public users
            isManageDeviceSettingsEnabled: isApplySettingsEnabled() || hasDistributorManageDevicesAdvancedAccess(),
            isMergeDevicesEnabled: hasDistributorManageDevicesAdvancedAccess(),
            isApplySettingsEnabled: isApplySettingsEnabled() || hasDistributorManageDevicesAdvancedAccess(),
            token: this.authService.getToken(),
            url: this.authService.getColdFusionAppUrl(props.isAdmin),
            showSettings: path ? path.indexOf('/settings') !== -1 : false
        };

        this.navigation = new NavigationServices();
    }

    /**
     * @param {*} menu
     * @param {*} display
     * @return {*}
     */
    renderMenuItem(menu, display) {
        const active = window.location.pathname ? window.location.pathname.indexOf(menu) !== -1 ? 'active_tab_sub' : '' : '';
        return this.props.excludedBySubscription(menu, 'pages') ? '' : (
            <li>
                <a className={'headerMenu ' + active} href={this.navigation.getUrl(menu)} >{display}</a>
            </li>
        );
    }

    /**
     * @return {*}
     */
    render() {
        const {
            showSettings,
            isEditDeviceSettingsEnabled,
            isManageDeviceSettingsEnabled,
            isApplySettingsEnabled,
            isMergeDevicesEnabled
        } = this.state;

        const { t, isAdmin, isPartner, profile } = this.props;

        const isNitroEssentials = checkNitroEssentials(profile);

        if (!isAdmin && !isPartner && this.authService.isLoggedIn() && showSettings) {
            return (
                <div className='subMenu menuBar hme-l-size'>
                    <ul className='subHeaders' id='settings'>
                        {this.renderMenuItem('stores', t('common__stores'))}
                        {this.renderMenuItem('users', t('common__users'))}
                        {this.renderMenuItem('roles', t('common__roles'))}
                        {!isNitroEssentials && this.renderMenuItem('display', t('sub-header--settings__display'))}
                        {isEditDeviceSettingsEnabled &&
                            this.renderMenuItem('lockDeviceSettings', t('sub-header--settings__lock-device-settings'))}
                        {isManageDeviceSettingsEnabled &&
                            this.renderMenuItem('manageDeviceSettings', t('common__settings-snapshots'))}
                        {isApplySettingsEnabled &&
                            this.renderMenuItem(
                                    'applyDeviceSettings',
                                    t(hasDistributorAccess() ? 'common__device-settings' : 'common__apply-device-settings')
                            )
                        }
                        {isMergeDevicesEnabled && this.renderMenuItem('mergeDevices', t('common__merge-devices'))}
                        {this.renderMoreMenu()}
                    </ul>
                </div>
            );
        }

        return null;
    }

    /**
     * @return {*}
     */
    renderMoreMenu() {
        const { t } = this.props;
        const title = <span className='headerMenu'>{t('main-header__item--more')}<span className='fa fa-chevron-down'></span></span>;

        return (
            <NavDropdown className='more-s' title={title} id='settingsMore'>
                {this.renderMenuItem('stores', t('common__stores'))}
                {this.renderMenuItem('users', t('common__users'))}
                {this.renderMenuItem('roles', t('common__roles'))}
                {this.renderMenuItem('display', t('sub-header--settings__display'))}
            </NavDropdown>
        );
    }
}

SettingsHeader.propTypes = {
    isAdmin: PropTypes.bool.isRequired
};

export default compose(
        withTranslation(),
        withSubscriptionBasedExclusions()
)(SettingsHeader);
