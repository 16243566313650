import { DateLib } from '@hme-cloud/utility-common';

export const tokenTypes = {
    DXS: 'DXS',
    VAIO: 'VAIO'
};

export const tokenTypeIds = {
    [tokenTypes.DXS]: 2,
    [tokenTypes.VAIO]: 1
};

export const tokenIdTypes = {
    [tokenTypeIds.DXS]: tokenTypes.DXS,
    [tokenTypeIds.VAIO]: tokenTypes.VAIO
};

const tokenNameTranslations = new Map([
    ['VAIO Provider', 'api-token-management__form--hme-apis-vaio-value'],
    ['DXS Provider', 'api-token-management__form--hme-apis-dxs-value']
]);

export const getTokenNameTranslation = (tokenName) => {
    if (tokenNameTranslations.has(tokenName) === false) {
        return false;
    }

    return tokenNameTranslations.get(tokenName);
};

export const partnerTokenTypeIds = {
    [tokenTypes.VAIO]: 1,
    [tokenTypes.DXS]: 4
};

const partnerTokenTypeTranslations = new Map([
    [partnerTokenTypeIds[tokenTypes.VAIO], 'api-token-management__form--hme-apis-vaio-value'],
    [partnerTokenTypeIds[tokenTypes.DXS], 'api-token-management__form--hme-apis-dxs-value']
]);

export const getPartnerTokenTypeTranslation = (tokenTypeId) => {
    if (partnerTokenTypeTranslations.has(tokenTypeId) === false) {
        return false;
    }

    return partnerTokenTypeTranslations.get(tokenTypeId);
};

export const expirationTypes = {
    'expired': 'expired',
    'within-month-before-expiration': 'within-month-before-expiration',
    'unexpired': 'unexpired'
};

export const getTokenExpirationType = (expiryDate) => {
    const expirationDate = new DateLib(expiryDate);

    const isPast = expirationDate.isBefore(new DateLib());
    if (isPast) {
        return expirationTypes.expired;
    }

    const isOneMonthBeforeExpiration = expirationDate.addMonths(-1).isBefore(new DateLib());
    if (isOneMonthBeforeExpiration) {
        return expirationTypes['within-month-before-expiration'];
    }

    return expirationTypes.unexpired;
};

