import React from 'react';
import { compose } from 'ramda';

import { withHMELayout } from 'HOCs/withHMELayout';
import { NotificationsList } from 'library/NotificationsList';

import { DeviceStatus } from './DeviceStatus/DeviceStatus';
import { useSystemStatus, useDevicesGrid } from './hooks';

import './SystemStatus.scss';

const PublicSystemStatusComponent = () => {
    const {
        device,
        deviceSettings,
        deviceUpgradeVersions,
        deviceScheduledUpgrade,
        isDataLoading,
        StatusInfoFields,
        error,
        onDeviceReboot,
        onDeviceReconnect
    } = useSystemStatus();

    const deviceGridProps = useDevicesGrid({ device, deviceScheduledUpgrade, deviceSettings, deviceUpgradeVersions });

    return (
        <>
            <NotificationsList />
            <DeviceStatus
                device={device}
                deviceSettings={deviceSettings}
                StatusInfoFields={StatusInfoFields}
                deviceGridProps={deviceGridProps}
                isLoading={isDataLoading}
                error={error}
                onDeviceReboot={onDeviceReboot}
                onDeviceReconnect={onDeviceReconnect}
            />
        </>
    );
};

export const PublicSystemStatus = compose(
        withHMELayout({
            contentClasses: ['public-zoom-nitro-system-status-page']
        })
)(PublicSystemStatusComponent);
