import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { INPUT_VARIANTS, InputComponent } from 'components/Inputs';
import './index.css';
import { validEmailRegexp } from 'constants/email';

const defaultInputVariants = [INPUT_VARIANTS.LABEL_INSIDE_MOBILE_ONLY];

const EmailChips = ({
  emailList,
  setEmailList,
  setFormValues,
  setIsInvalidEmail
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  /** update the input value every time it changes
   * @param {string} newValue new input value
  **/
  const onHandleChange = (newValue) => {
    const value = newValue;
    setValue(value);
    setErrorMessage('');
  }
  /** Check if the new email to be added is an email
   * @param {string} email email to be added
  **/
  const isEmail = (email) => {
    return validEmailRegexp.test(email);
  }
  /** Check if the new email to be added already exists
   * @param {string} email email to be added
  **/
  const isInList = (email) => {
    if (emailList.includes(email)) {
      setErrorMessage(t("scheduler__chips-email-message-in-list"));
      return true;
    }
    return false;
  };
  /** Check if the new email to be added is valid
   * @param {string} email email to be added
  **/
  const isValid = (email) => {
    let error = null;

    if (!isEmail(email)) {
      error = email + ' ' + t("scheduler__chips-email-message-no-valid");
    }

    if (isInList(email)) {
      error = email + ' ' + t("scheduler__chips-email-message-already-exists");
    }

    if(email.length > 50){
        error = email + ' ' + t("common__error--invalid-email");
    }

    if (error) {
      setErrorMessage(error);
      setIsInvalidEmail(true);
      return false;
    }

    return true;
  }
  /**Set the email list if the new emails are valid
   * @param {object} emails new emails
  **/
  const addEmail = (emails) => {
    if (emails.length >= 10) {
      setEmailList(emails.slice(0, 10));
      setErrorMessage(t("scheduler__chips-email-message-limit-reached"));
    } else {
      setEmailList(emails);
    }
  }
  // Keep the mails value updated
  useEffect(() => {
    if (emailList.length > 0) {
      const mails = emailList.join(';');
      setFormValues({
        mails
      })
    } else {
      setFormValues({
        mails: ''
      })
    }
  }, [emailList]);

  // Set the isInvalidEmail state when the email is an empty string
  useEffect(() => {
    if (errorMessage === '') {
      setIsInvalidEmail(false);
    }
  }, [errorMessage]);
  

  /** Remove the tag selected and set the error message to empty
   * @param {string} toBeRemoved email to be removed
  **/
  const onHandleDelete = (toBeRemoved) => {
    setEmailList(
      emailList.filter(email => email !== toBeRemoved)
    )
    setErrorMessage('');
  }
  /**  Add the input value when these keys are pressed
  *   @param {string} event key pressed
  **/
  const onHandleKeyDown = (event) => {
    if (["Tab", "Enter", ";"].includes(event.key)) {
      event.preventDefault();
      const email = value.trim();
      if (email && isValid(email)) {
        addEmail([...emailList, email]);
        setValue("");
      }
    }
  }
  /** Create the tags from the string value separated by semicolons
   * @param {object} event paste event object
  **/
  const onHandlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData('text');
    const emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      const toBeAdded = emails.filter((email) => !isInList(email));
      addEmail([...emailList, ...toBeAdded]);
    }
  };
  //Create the tags from the string value when the click is out the form
  const onHandleBlur = () => {
    if (value.length > 0) {
      const email = value.trim();
      if (email && isValid(email)) {
        addEmail([...emailList, email]);
        setValue("");
      }
    }
  };
  return (
    <>
      <InputComponent
        value={value}
        onChange={(event) => onHandleChange(event)}
        isRequired
        placeholder={t('scheduler__create-recipient-email')}
        label="scheduler__create-recipient-email"
        onKeyDown={(event) => onHandleKeyDown(event)}
        onPaste={(event) => onHandlePaste(event)}
        onBlur={(event) => onHandleBlur(event)}
        className="create-edit-scheduler__text-input"
        variants={defaultInputVariants}
      />
      {errorMessage &&
        <div className="error wrap-text">{errorMessage}</div>
      }
      <div className="clearance">
        {emailList.map((email, index) => (
          <div key={index} className="tag-item">
            <div className='wrap-text'>{email}</div>
            <button
              type="button"
              onClick={() => onHandleDelete(email)}
              className="button"
            >
              &times;
            </button>
          </div>
        ))
        }
      </div>
    </>
  );
};

export default EmailChips;
