import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Tip } from 'library/Tip';
import { Title } from 'library/Title';
import { Separator, SEPARATOR_VARIANTS } from 'library/Separator';
import { Button, BUTTON_VARIANTS } from 'library/Button';

import './Header.scss';

const separatorVaraiants = [SEPARATOR_VARIANTS.TITLE];
const transparentButtonVariants = [BUTTON_VARIANTS.TRANSPARENT];

export const Header = ({ title, showResend, onResend, onMasquerade }) => {
    const { t } = useTranslation();

    const onResendClick = useCallback(() => {
        onResend && onResend();
    }, [onResend]);

    const onMasqueradeClick = useCallback(() => {
        onMasquerade?.();
    }, [onMasquerade]);

    return (
        <div className="hme-partner-form__header">
            <Title>{t(title)}</Title>
            {
                showResend &&
                <>
                    <Separator variants={separatorVaraiants} />
                    <Button
                        variants={transparentButtonVariants}
                        onClick={onResendClick}
                    >{t('admin__partner__form__title__resend')}</Button>
                </>
            }
            <Separator variants={separatorVaraiants} />
            <div className="hme-partner-form__header-item">
                <Button
                    variants={transparentButtonVariants}
                    onClick={onMasqueradeClick}
                    disabled={!showResend}
                >{t('add-user__masquerade-as-user')}</Button>
                {!showResend && (
                    <Tip placement="right">
                        {t('add-user__warning--masquerade-unavailable--inactive')}
                    </Tip>
                )}
            </div>
        </div>
    )
};
