import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingModal } from 'library/LoadingModal';
import { ConfirmPopupComponent } from 'library/ConfirmPopup';
import { Grid } from 'components/Common/Grid';

import { useTokensGrid } from './hooks';

export const headers = [
    {
        text: 'admin__token-management__token-name',
        property: 'name'
    },
    {
        text: 'admin__token-management__token-expiration-date',
        property: 'expiryDate',
        sortable: true,
        flex: 0.6,
        headerClassName: 'hme-grid-centered-cell',
        className: 'hme-grid-centered-cell'
    },
    {
        text: 'admin__token-management__token-apis',
        property: 'apis',
        headerClassName: 'hme-grid--header-text-transform--none',
        flex: 0.5
    },
    {
        text: 'common__created',
        property: 'createdAt'
    },
    {
        text: 'common__created-by',
        property: 'createdBy'
    },
    {
        text: 'common__updated',
        property: 'updatedAt'
    },
    {
        text: 'common__updated-by',
        property: 'updatedBy'
    },
    {
        text: 'common__actions',
        property: 'Actions',
        flex: 0.7,
        headerClassName: 'hme-grid-centered-cell'
    }
];

export const List = ({ tokens, onRemoveToken, ...props }) => {
    const { t } = useTranslation();

    const { rows, isRemoveConfirmationShown, onRemoveCancel, handleConfirmRemoveClick, isLoadingModalShown } = useTokensGrid(
            { tokens, onRemoveToken }
    );

    return (
        <>
            <Grid
                rows={rows}
                headers={headers}
                noRecordsMessage={t('admin__token-management__token-not-found')}
                rowKey="id"
                className="hme-token-management__list"
                {...props}
            />
            <LoadingModal show={isLoadingModalShown} />
            <ConfirmPopupComponent
                show={isRemoveConfirmationShown}
                message={t('admin__token-management__list__confirm--remove')}
                onConfirm={handleConfirmRemoveClick}
                onHide={onRemoveCancel}
                cancelVerb={t('common__popup--cancel-action')}
                confirmationVerb={t('common__popup--confirm-action')}
            />
        </>
    );
};
